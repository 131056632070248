.select-input{
   width: 400px !important;
   &:focus{
      box-shadow: none !important;
      border: none !important;
   }
}
.select-input1{
   &:focus{
      box-shadow: none !important;
      border: none !important;
   }
}
.nomal-btn{
   border: none;
   &:focus{
      box-shadow: none !important;
      // border: none !important;
   }
}
.select-input-product{
   // width: 300px !important;
   &:focus{
      box-shadow: none !important;
      border: none !important;
   }
}